@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap);
* {
    box-sizing: border-box;
    font-family: "Rubik",  "sans-serif";
    color: #393c41;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    overflow-x: hidden;
}

@keyframes animateDown {

    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(5px);
    }

    60% {
        transform: translateY(3px);
    }
}

a {
    text-decoration: none ;
}
.Counter_row__1C_4f {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter_row__1C_4f > button {
  margin-left: 4px;
  margin-right: 8px;
}
.Counter_row__1C_4f:not(:last-child) {
  margin-bottom: 16px;
}

.Counter_value__1d0te {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.Counter_button__1xpSV {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.Counter_textbox__3ODaX {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 4px;
}

.Counter_button__1xpSV:hover,
.Counter_button__1xpSV:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.Counter_button__1xpSV:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.Counter_asyncButton__2UAr3 {
  position: relative;
}

.Counter_asyncButton__2UAr3:after {
  content: '';
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.Counter_asyncButton__2UAr3:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

