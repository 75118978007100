@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

* {
    box-sizing: border-box;
    font-family: "Rubik",  "sans-serif";
    color: #393c41;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    overflow-x: hidden;
}

@keyframes animateDown {

    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(5px);
    }

    60% {
        transform: translateY(3px);
    }
}

a {
    text-decoration: none ;
}